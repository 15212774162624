import { useTranslation } from "react-i18next";



export const GeneralOptions = (key) => {

  const { i18n, t } = useTranslation();

  // #region Options

  const costTypologyOptions = [
    {
      label: t("Accommodation"),
      value: "accommodation",
    },
    {
      label: t("Company"),
      value: "company",
    },
    {
      label: t("Booking"),
      value: "booking",
    },
  ];

  const paymentTypeOptions = [
    {
      label: t("Fixed"),
      value: "fixed",
    },
    {
      label: t("Percentage"),
      value: "percentage",
    }
  ];

  const includingOptions = [
    {
      label: t("Included"),
      value: "included",
    },
    {
      label: t("Excluded"),
      value: "excluded",
    }
  ];

  const contractOptions = [
    {
      label: t("FixedRent"),
      value: "fixed-rent",
    },
    {
      label: t("RevenueShare"),
      value: "revenue-share",
    },
    {
      label: t("CommissionBased"),
      value: "commission-based"
    },
    {
      label: t("GuaranteedIncome"),
      value: "guaranteed-income"
    },
    {
      label: t("OnlyAgency"),
      value: "only-agency"
    },
    {
      label: t("FeeBased"),
      value: "fee-based"
    },
    {
      label: t("HybridModels"),
      value: "hybrid-models"
    },
    {
      label: t("TieredRevenueShare"),
      value: "tiered-revenue-share"
    },
    {
      label: t("PerformanceBasedBonus"),
      value: "performance-based-bonus"
    },
    {
      label: t("BookingFeeModel"),
      value: "booking-fee-model"
    },
    {
      label: t("FullServiceVariableFee"),
      value: "full-service-with-variable-fee"
    },
    {
      label: t("RetainerPlusCommission"),
      value: "retainer-plus-commission"
    },
    {
      label: t("EquityShareModel"),
      value: "equity-share-model"
    },
    {
      label: t("ZeroFeeUpsells"),
      value: "zero-fee-with-upsells"
    },
  ];

  const statusOptions = [
    {
      label: t("Active"),
      value: "active"
    },
    {
      label: t("Inactive"),
      value: "inactive"
    }
  ];

  const otaCommissionOptions = [
    {
      label: t("Included"),
      value: true,
    },
    {
      label: t("Excluded"),
      value: false,
    }
  ];

  const recurrencyOptions = [
    {
      label: t("Daily"),
      value: "daily"
    },
    {
      label: t("Weekly"),
      value: "weekly"
    },
    {
      label: t("Monthly"),
      value: "monthly",
    },
    {
      label: t("Yearly"),
      value: "yearly",
    },
    {
      label: t("Custom"),
      value: "custom"
    }
  ];

  const costDateOptions = [
    {
      label: t("BookingDate"),
      value: "booking"
    },
    {
      label: t("Check-In date"),
      value: "check-in"
    },
    {
      label: t("Check-Out date"),
      value: "check-out",
    }
  ];

  const bookingStatusOptions = [
    {
      label: t("Paid"),
      value: 'paid'
    },
    {
      label: t("Booked"),
      value: "booked"
    },
    {
      label: t("Canceled"),
      value: "canceled",
    }
  ];

  const languageOptions = [
    {
      label: t("English"),
      value: "en"
    },
    {
      label: t("Spanish"),
      value: "es"
    },
    {
      label: t("French"),
      value: "fr"
    },
    {
      label: t("Italian"),
      value: "it"
    }
  ];

  const currencyOptions = [
    {
      "value": "$",
      "name": "US Dollar",
      "label": "USD"
    },
    {
      "value": "CA$",
      "name": "Canadian Dollar",
      "label": "CAD"
    },
    {
      "value": "€",
      "name": "Euro",
      "label": "EUR"
    },
    {
      "value": "AU$",
      "name": "Australian Dollar",
      "label": "AUD"
    },
    {
      "value": "£",
      "name": "British Pound",
      "label": "GBP"
    }
      
  ];
  // #endregion

  switch (key) {
    case "contract":
      return contractOptions;
    case "status":
      return statusOptions;
    case "paymentType":
      return paymentTypeOptions;
    case "otaCommission":
      return otaCommissionOptions;
    case "costDate":
      return costDateOptions;
    case "bookingStatus":
      return bookingStatusOptions;
    case "recurrency":
      return recurrencyOptions;
    case "including":
      return includingOptions;
    case "costTypology":
      return costTypologyOptions;
    case "language":
      return languageOptions;
    case "currency":
      return currencyOptions;
  }

}






