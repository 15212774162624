import React, { useRef, useState, useImperativeHandle, forwardRef } from "react";
import { HiOutlineCalendar } from "react-icons/hi";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "./style.css";
import { useTranslation } from "react-i18next";

export const NewDatePicker = forwardRef(({
  inputClassName,
  className,
  defaultValue,
  placeholder,
  label,
  labelClassName,
  onClick,
  menu,
  onCompleted,
  isFilter
}, ref) => {
  const [selected, setSelected] = useState(defaultValue ? defaultValue : null);
  const [isOpen, setIsOpen] = useState(false);
  const innerRef = useRef(null);

  const { i18n, t } = useTranslation();
  useImperativeHandle(ref, () => ({
    clearDate: () => {
      setSelected(null);
    }
  }));

  let footer = <p>Please pick a day.</p>;
  if (selected) {
    footer = <p>{format(selected, "PPP")}.</p>;
  }

  const clickOutside = (e) => {
    if (innerRef.current && isOpen && !innerRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  document.addEventListener("mousedown", clickOutside);

  return (
    <div
      ref={innerRef}
      className={
        className + " " + "flex flex-col items-start justify-start w-full "
      }
    >
      {label && (
        <label
          className={
            labelClassName + " " + "text-lg font-semibold text-blue w-full"
          }
        >
          {label}
        </label>
      )}
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={
          inputClassName +
          " " + (isFilter ?  " bg-white border-slate-300 border-solid border-[1px] py-1 px-1 " : " bg-input py-3 px-1 ") +

          "w-full h-fit my-1 text-base rounded-md outline-none indent-2 text-blue text-left min-h-[25px]  flex items-center"
        }
      >
        <HiOutlineCalendar className="ml-1 text-xl" />
        {selected ? footer : placeholder ? placeholder : t("SelectDate")}
      </button>
      {isOpen && (
        <div className="custom-calendar">
          <DayPicker
            mode="single"
            selected={selected}
            onSelect={(date) => {
              setSelected(date);
              setIsOpen(false);
              onCompleted && onCompleted(date);
            }}
          />
        </div>
      )}
    </div>
  );
});

export default NewDatePicker;
