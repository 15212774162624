import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { getAuth } from "firebase/auth";
import TextField from "../input/fields/text-field";
import { SelectMenu } from "../input/select/select-menu";
import { DataContext } from "../../App";
import Button from '../buttons/tertiary-button';
import NewDatePicker from '../input/fields/date-pickers/newDatePicker';
import TagsSelect from '../input/select/tags/tags-select';
import { Checkbox } from 'primereact/checkbox';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { GeneralOptions } from "../../resources/selectOptions";

const FilterComponent = ({ onComplete, values, typeFilter }) => {
  const { t } = useTranslation();
  const context = useContext(DataContext);
  const [initialValues, setInitialValues] = useState(values);
  const [valuesToSend, setValuesToSend] = useState([]);
  const [bookingDate, setBookingDate] = useState('');
  const [bookingReference, setBookingReference] = useState('');
  const [costCenter, setCostCenter] = useState('');
  const [accommodation, setAccommodation] = useState('');
  const [accommodationBooking, setAccommodationBooking] = useState('');
  const [checkInDate, setCheckInDate] = useState('');
  const [checkOutDate, setCheckOutDate] = useState('');
  const [city, setCity] = useState('');
  const [owners, setOwners] = useState('');
  const [otas, setOtas] = useState('');
  const [isActiveStatus, setIsActiveStatus] = useState(false);
  const [isInactiveStatus, setIsInactiveStatus] = useState(false);
  const [ruleName, setRuleName] = useState('');
  const [typology, setTypology] = useState('');
  const [category, setCategory] = useState('');
  const [accommodations, setAccommodations] = useState('');
  const datePickerRef = useRef();
  const [minRangeValue, setMinRangeValue] = useState(1000);
  const [maxRangeValue, setMaxRangeValue] = useState(3500);
  const [ruleNameTags, setRuleNameTags] = useState([]);
  const [typologyTags, setTypologyTags] = useState([]);
  const [categoryTags, setCategoryTags] = useState([]);
  const [accommodationsTags, setAccommodationsTags] = useState([]);
  const [cityTags, setCityTags] = useState([]);
  const [otasTags, setOtasTags] = useState([]);
  const [creationDate, setCreationDate] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [costDate, setCostDate] = useState('');
  const [recurrenceTags, setRecurrenceTags] = useState([]);
  const [fixedPayment, setFixedPayment] = useState(false);
  const [percentagePayment, setPercentagePayment] = useState(false);
  const [activeRange, setActiveRange] = useState(false);
  const [supplier, setSupplier] = useState(false);
  const [clearTagsTrigger, setClearTagsTrigger] = useState(false);

  const options = [
    { label: 'Cleaning', value: 'Cleaning' },
    { label: 'Cooking', value: 'Cooking' },
    { label: 'Shopping', value: 'Shopping' },
  ];

  const contractOptions = GeneralOptions("contract");
  const recurrencyOptions = GeneralOptions("recurrency");

  const cities = [
    ...new Set(
      context.accommodations
        .map(accommodation => {
          const city = accommodation.address?.city;
          return typeof city === 'string' ? city.charAt(0).toUpperCase() + city.toLowerCase().slice(1) : null;
        })
        .filter(city => city !== null)
    )
  ];

  const cityOptions = cities.map(city => ({
    value: city,
    label: city,
  }));


  const ownerOptions = context.owners.map(owner => ({
    value: owner.id,
    label: owner.fullNameCompanyName
  }));

  const accommodationOptions = context.accommodations.map(accommodation => ({
    value: accommodation.id,
    label: accommodation.name
  }));

  const ruleNameOptions = context.bookingRules.map(rule => ({
    value: rule.name,
    label: rule.name

  }));

  const typologyOptions = [

    { label: 'Cost', value: 'cost' },
    { label: 'Earning', value: 'earning' }
  ];

  const costCategoryOptions = context.costCategories.map(costRule => ({
    value: costRule.category,
    label: costRule.category,
  }))

  const otaOptions = context.otas.map(ota => ({
    value: ota.name,
    label: ota.name,
  }))

  const suppliersOptions = context.suppliers.map(ota => ({
    value: ota.fullNameCompanyName,
    label: ota.fullNameCompanyName,
  }))



  const resetFilters = () => {
    setBookingDate('');
    setBookingReference('');
    setOwners([]);
    setSupplier('')
    setAccommodation('');
    setCheckInDate('');
    setCheckOutDate('');
    setPaymentDate('');
    setRuleNameTags([]);
    setTypologyTags([]);
    setCategoryTags([]);
    setCityTags([]);
    setAccommodationsTags([]);
    setRecurrenceTags([]);
    setFixedPayment(false);
    setPercentagePayment(false);
    setIsActiveStatus(false);
    setIsActiveStatus(false);
    setIsInactiveStatus(false);
    setCity([]);
    setMinRangeValue(1500);
    setMaxRangeValue(3000);
    setClearTagsTrigger(prev => !prev);
    if (datePickerRef?.current) {
      datePickerRef.current.clearDate();
    }
    onComplete(initialValues);
  };


  const handleSelectedTags = (setter) => (tags) => {
    setter(tags);
  };

  const handleRangeChange = (e) => {
    setActiveRange(true)
    const [min, max] = e;
    setMinRangeValue(min);
    setMaxRangeValue(max);
  };

  const compareDates = (bookingDateStr, dateStr) => {
    const bookingDate = new Date(bookingDateStr).setUTCHours(0, 0, 0, 0);
    const filterDate = new Date(dateStr).setUTCHours(0, 0, 0, 0);
    return bookingDate === filterDate;
  };

  const setFilters = () => {
    let filteredValues = initialValues;
    if (bookingReference) {
      filteredValues = filteredValues.filter(x =>
        x.bookingReference.includes(bookingReference)
      );
    }

    if (costCenter) {
      filteredValues = filteredValues.filter(x =>
        x.costCenter.includes(costCenter)
      );
    }

    if (owners.length > 0) {
      filteredValues = filteredValues.filter(x => x.idOwner && owners.includes(x.idOwner));

    }

    if (accommodation) {
      filteredValues = filteredValues.filter(x =>
        x.id === (accommodation)
      );
    }

    if (accommodationBooking) {
      filteredValues = filteredValues.filter(x =>
        x.idAccommodation.includes(accommodationBooking)
      );
    }

    if (accommodations) {
      filteredValues = filteredValues.filter(x =>
        x.idAccommodations && accommodationsTags.some(tag => x.idAccommodations.includes(tag))
      );
    }

    if (typologyTags.length > 0) {
      filteredValues = filteredValues.filter(x =>
        x.contractTypology && typologyTags.some(tag => x.contractTypology.includes(tag))
      );
    }

    if (cityTags.length > 0) {
      filteredValues = filteredValues.filter(x =>
        x.address && x.address.city && cityTags.some(tag =>
          x.address.city.toLowerCase().includes(tag.toLowerCase())
        )
      );
    }


    if (isActiveStatus) {
      filteredValues = filteredValues.filter(x =>
        x.status === 'active'
      );
    }

    if (fixedPayment) {
      filteredValues = filteredValues.filter(x =>
        x.paymentType === 'fixed'
      );
    }

    if (percentagePayment) {
      filteredValues = filteredValues.filter(x =>
        x.paymentType === 'percentage'
      );
    }

    if (isInactiveStatus) {
      filteredValues = filteredValues.filter(x =>
        x.status === 'inactive'
      );
    }

    if (ruleName) {
      filteredValues = filteredValues.filter(x =>
        x.name.includes(ruleName)
      );
    }

    if (typology) {
      filteredValues = filteredValues.filter(x =>
        x.type.includes(typology)
      );
    }
    if (categoryTags.length > 0) {
      filteredValues = filteredValues.filter(x =>
        x.category && categoryTags.some(tag => x.category.includes(tag))
      );
    }
    if (category.length > 0) {
      filteredValues = filteredValues.filter(x =>
        x.category && category.some(cat => x.category.includes(cat))
      );
    }

    if (otas.length > 0) {
      filteredValues = filteredValues.filter(x =>
        x.channel.commissionChannel && otas.some(tag => x.channel.commissionChannel.includes(tag))
      );
    }

    if (paymentDate) {
      filteredValues = filteredValues.filter(x =>

        compareDates(x.date, paymentDate)
      );
    }

    if (costDate) {
      filteredValues = filteredValues.filter(x =>

        compareDates(x.endDate, costDate)
      );
    }

    if (creationDate) {
      filteredValues = filteredValues.filter(x =>

        compareDates(x.startDate, creationDate)
      );
    }

    if (bookingDate) {
      filteredValues = filteredValues.filter(x =>

        compareDates(x.startDate, bookingDate)
      );
    }

    if (checkInDate) {
      filteredValues = filteredValues.filter(x =>
        compareDates(x.checkInDate, checkInDate)
      );
    }

    if (checkOutDate) {
      filteredValues = filteredValues.filter(x =>
        compareDates(x.checkOutDate, checkOutDate)
      );
    }

    if (activeRange) {
      filteredValues = filteredValues.filter(x => {
        return x.amount >= minRangeValue && x.amount <= maxRangeValue;
      });
    }



    setValuesToSend(filteredValues);
  };

  const applyFilters = () => {
    onComplete(valuesToSend);
  };

  useEffect(() => {
    if (valuesToSend.length > 0) {
      onComplete(valuesToSend);
    }
  }, [valuesToSend]);

  return (
    <>
      {typeFilter && typeFilter === "Booking" && (
        <div className="scrollable-content">
          <TextField
            label={t("BookingReference")}
            filterInput={true}
            inputClassName={"p-1"}
            className={"max-w-[13vw]"}
            placeholder={t("Insert")}
            value={bookingReference}
            onChange={(e) => setBookingReference(e.target.value)}
          />
          <hr className='mt-3 mb-3' />
          <TagsSelect
            options={accommodationOptions}
            selectedTags={(tags) => {
              handleSelectedTags(setAccommodationsTags)(tags);
              setAccommodationBooking(tags);
            }}
            placeholder={t("Select")}
            label={t("Accommodations")}
            labelClassName="custom-label mb-3"
            value={accommodationBooking}
            clearTrigger={clearTagsTrigger}
          />
          <hr className='mt-3 mb-3' />
          <NewDatePicker
            label={t("BookingDate")}
            onCompleted={(el) => setBookingDate(el)}
            labelClassName="mb-3"
            className={"date-picker-filters-custom"}
            ref={datePickerRef}
          />
          <hr className='mt-3 mb-3' />
          <div className="flex">
            <NewDatePicker
              label={t("CheckIn")}
              onCompleted={(el) => setCheckInDate(el)}
              labelClassName="mb-3"
              className={"date-picker-filters-custom mr-5"}
              ref={datePickerRef}
            />
            <NewDatePicker
              label={t("CheckOut")}
              onCompleted={(el) => setCheckOutDate(el)}
              labelClassName="mb-3"
              className={"date-picker-filters-custom ml-5"}
              ref={datePickerRef}
            />
          </div>
          <hr className='mt-3 mb-3' />
          <label className='custom-label mb-3 ml-1 text-lg font-semibold text-blue w-full'>{t("CostAmount")}</label>
          <div className="slider-container mt-5 mb-5 pb-5" style={{ position: 'relative', width: '300px' }}>
            <RangeSlider
              id="range-slider-black"
              min={0}
              max={6000}
              step={10}
              defaultValue={[minRangeValue, maxRangeValue]}
              onInput={handleRangeChange}
            />

            <div
              className="slider-value-min"
              style={{
                position: 'absolute',
                left: `calc(${(minRangeValue / 6000) * 100}% )`,
                top: '15px',
              }}
            >
              {minRangeValue}
            </div>

            <div
              className="slider-value-max"
              style={{
                position: 'absolute',
                left: `calc(${(maxRangeValue / 6000) * 100}% )`,
                top: '15px',
              }}
            >
              {maxRangeValue}
            </div>
          </div>
          <hr className='mt-5 pt-5 mb-3' />
          <TagsSelect
            options={ownerOptions}
            selectedTags={(tags) => {
              handleSelectedTags(setAccommodationsTags)(tags);
              setOwners(tags);
            }}
            placeholder={t("Select")}
            label={t("Owners")}
            labelClassName="custom-label mb-3"
            clearTrigger={clearTagsTrigger}
          />
          <hr className='mt-3 mb-3' />
          <TagsSelect
            options={otaOptions}
            selectedTags={(tags) => {
              handleSelectedTags(setOtasTags)(tags);
              setOtas(tags);
            }}
            placeholder={t("Select")}
            label={t("Channel")}
            labelClassName="custom-label mb-3"
            clearTrigger={clearTagsTrigger}
          />
          <hr className='mt-3 mb-3' />
        </div>
      )}

      {typeFilter && typeFilter === "Transaction" && (
        <div className="scrollable-content">
          <TagsSelect
            options={typologyOptions}
            selectedTags={(tags) => {
              handleSelectedTags(setTypologyTags)
              setTypology(tags)
            }}
            placeholder={t("Select")}
            label={t("Typology")}
            labelClassName="custom-label mb-3"
            clearTrigger={clearTagsTrigger}
          />
          <hr className='mt-3 mb-3' />
          <TextField
            label={t("CostCenter")}
            filterInput={true}
            inputClassName={"p-1"}
            className={"max-w-[13vw]"}
            placeholder={t("Insert")}
            value={costCenter}
            onChange={(e) => setCostCenter(e.target.value)}
          />
          <hr className='mt-3 mb-3' />
          <TagsSelect
            options={accommodationOptions}
            selectedTags={(tags) => {
              handleSelectedTags(setAccommodationsTags)(tags);
              setAccommodationBooking(tags);
            }}
            placeholder={t("Select")}
            label={t("Accommodations")}
            labelClassName="custom-label mb-3"
            value={accommodationBooking}
            clearTrigger={clearTagsTrigger}
          />
          <hr className='mt-3 mb-3' />
          <TagsSelect
            options={costCategoryOptions}
            selectedTags={(tags) => {
              handleSelectedTags(setCategoryTags)
              setCategory(tags)
            }}
            placeholder={t("Select")}
            label={t("Category")}
            labelClassName="custom-label mb-3"
            clearTrigger={clearTagsTrigger}
          />
          <hr className='mt-3 mb-3' />
          <NewDatePicker
            ref={datePickerRef}
            label={t("PaymentDate")}
            onCompleted={(el) => setPaymentDate(el)}
            labelClassName="mb-3"
            className={"date-picker-filters-custom"}

          />
          <hr className='mt-3 mb-3' />
          <label className='custom-label mb-3 ml-1 text-lg font-semibold text-blue w-full'>{t("CostAmount")}</label>
          <div className="slider-container mt-5 mb-5 pb-5" style={{ position: 'relative', width: '300px' }}>
            <RangeSlider
              id="range-slider-black"
              min={0}
              max={6000}
              step={10}
              defaultValue={[minRangeValue, maxRangeValue]}
              onInput={handleRangeChange}
            />

            <div
              className="slider-value-min"
              style={{
                position: 'absolute',
                left: `calc(${(minRangeValue / 6000) * 100}% )`,
                top: '15px',
              }}
            >
              {minRangeValue}
            </div>

            <div
              className="slider-value-max"
              style={{
                position: 'absolute',
                left: `calc(${(maxRangeValue / 6000) * 100}% )`,
                top: '15px',
              }}
            >
              {maxRangeValue}
            </div>
          </div>
          <hr className='mt-5 pt-5 mb-3' />
          <NewDatePicker
            label={t("CostDate")}
            onCompleted={(el) => setCostDate(el)}
            labelClassName="mb-3"
            className={"date-picker-filters-custom"}
            ref={datePickerRef}
          />
          <hr className='mt-3 mb-3' />
          <SelectMenu
            label={t("Supplier")}
            placeholder={t("Select")}
            className={"select-filter-white"}
            inputClassName={"p-1"}
            options={suppliersOptions}
            onChange={(item) => setSupplier(item)}
            value={supplier}
          />
          <hr className='mt-3 mb-3' />
        </div>
      )}


      {typeFilter && typeFilter === "BookingRule" && (
        <div className="scrollable-content">
          <TagsSelect
            options={ruleNameOptions}
            selectedTags={(tags) => {
              handleSelectedTags(setRuleNameTags)
              setRuleName(tags)
            }}
            placeholder={t("Select")}
            label={t("RuleName")}
            labelClassName="custom-label mb-3"
            clearTrigger={clearTagsTrigger}
          />
          <hr className='mt-3 mb-3' />
          <TagsSelect
            options={typologyOptions}
            selectedTags={(tags) => {
              handleSelectedTags(setTypologyTags)
              setTypology(tags)
            }}
            placeholder={t("Select")}
            label={t("Typology")}
            labelClassName="custom-label mb-3"
            clearTrigger={clearTagsTrigger}
          />
          <hr className='mt-3 mb-3' />
          <TagsSelect
            options={costCategoryOptions}
            selectedTags={(tags) => {
              handleSelectedTags(setCategoryTags)
              setCategory(tags)
            }}
            placeholder={t("Select")}
            label={t("Category")}
            labelClassName="custom-label mb-3"
            clearTrigger={clearTagsTrigger}
          />
          <hr className='mt-3 mb-3' />
          <TagsSelect
            options={accommodationOptions}
            selectedTags={(tags) => {
              handleSelectedTags(setAccommodationsTags)(tags);
              setAccommodations(tags);
            }}
            placeholder={t("Select")}
            label={t("Accommodations")}
            labelClassName="custom-label mb-3"
            clearTrigger={clearTagsTrigger}
          />
          <hr className='mt-3 mb-3' />
          <NewDatePicker
            ref={datePickerRef}
            label={t("CreationDate")}
            onCompleted={(el) => setCreationDate(el)}
            labelClassName="mb-3"
            className={"date-picker-filters-custom"}
          />
          <hr className='mt-3 mb-3' />
          <label className='custom-label mb-3 ml-1 text-lg font-semibold text-blue w-full'>{t("PaymentType")}</label>
          <div className="flex ml-2">
            <div className="flex items-center mt-6">
              <Checkbox
                id={'fixed'}
                onChange={e => setFixedPayment(e.checked)}
                checked={fixedPayment}
              />
              <p className='ml-2'>{t("Fixed")}</p>
            </div>
            <div className="flex items-center mt-6 ml-5">
              <Checkbox
                id={'percentage'}
                onChange={e => setPercentagePayment(e.checked)}
                checked={percentagePayment}
              />
              <p className='ml-2'>{t("Percentage")}</p>
            </div>
          </div>
          <hr className='mt-3 mb-3' />
          <TagsSelect
            options={recurrencyOptions}
            selectedTags={handleSelectedTags(setRecurrenceTags)}
            placeholder={t("Select")}
            label={t("Recurrence")}
            labelClassName="custom-label mb-3"
            clearTrigger={clearTagsTrigger}
          />
          <hr className='mt-3 mb-3' />
          <label className='custom-label mb-3 ml-1 text-lg font-semibold text-blue w-full'>{t("Status")}</label>
          <div className="flex ml-2">
            <div className="flex items-center mt-6">
              <Checkbox
                id={'active'}
                onChange={e => setIsActiveStatus(e.checked)}
                checked={isActiveStatus}
              />
              <p className='ml-2'>{t("Active")}</p>
            </div>
            <div className="flex items-center mt-6 ml-5">
              <Checkbox
                id={'inactive'}
                onChange={e => setIsInactiveStatus(e.checked)}
                checked={isInactiveStatus}
              />
              <p className='ml-2'>{t("Inactive")}</p>
            </div>
          </div>
        </div>
      )}


      {typeFilter && typeFilter === "Accommodation" && (
        <div className="scrollable-content">
          <TagsSelect
            options={ownerOptions}
            selectedTags={(tags) => {
              handleSelectedTags(setAccommodationsTags)(tags);
              setOwners(tags);
            }}
            placeholder={t("Select")}
            label={t("Owners")}
            labelClassName="custom-label mb-3"
            clearTrigger={clearTagsTrigger}
          />
          <hr className='mt-3 mb-3' />
          <TagsSelect
            options={contractOptions}
            selectedTags={(tags) => {
              handleSelectedTags(setAccommodationsTags)(tags);
              setTypologyTags(tags);
            }}
            placeholder={t("Select")}
            label={t("ContractTypology")}
            labelClassName="custom-label mb-3"
            clearTrigger={clearTagsTrigger}
          />
          <hr className='mt-3 mb-3' />
          <TagsSelect
            label={t("City")}
            placeholder={t("Select")}
            labelClassName="custom-label mb-3"
            clearTrigger={clearTagsTrigger}
            options={cityOptions}
            onChange={(item) => setCity(item)}
            selectedTags={(tags) => {
              handleSelectedTags(setCityTags)(tags);
              setCity(tags);
            }}
          />
          <hr className='mt-3 mb-3' />
          <TagsSelect
            options={accommodationOptions}
            selectedTags={(tags) => {
              handleSelectedTags(setAccommodationsTags)(tags);
              setAccommodation(tags);
            }}
            placeholder={t("Select")}
            label={t("Accommodations")}
            labelClassName="custom-label mb-3"
            value={accommodation}
            clearTrigger={clearTagsTrigger}
          />
          <hr className='mt-3 mb-3' />
          <label className='custom-label mb-3 ml-1 text-lg font-semibold text-blue w-full'>{t("Status")}</label>
          <div className="flex ml-2">
            <div className="flex items-center mt-6">
              <Checkbox
                id={'active'}
                onChange={e => setIsActiveStatus(e.checked)}
                checked={isActiveStatus}
              />
              <p className='ml-2'>{t("Active")}</p>
            </div>
            <div className="flex items-center mt-6 ml-5">
              <Checkbox
                id={'inactive'}
                onChange={e => setIsInactiveStatus(e.checked)}
                checked={isInactiveStatus}
              />
              <p className='ml-2'>{t("Inactive")}</p>
            </div>
          </div>
          <hr className='mt-3 mb-3' />
        </div>
      )}

      <div className='flex justify-between items-center px-[1vw]'>
        <Button
          id={"resetFilters"}
          className={"mt-4 btn btn-secondary text-petroil-1"}
          onClick={resetFilters}
          content={t("ResetFilters")}
          isDisabled={false}
        />
        <Button
          id={"applyFilters"}
          className={"mt-4 btn btn-primary bg-petroil p-2"}
          onClick={() => {
            setFilters();
            applyFilters();
          }}
          content={t("ApplyFilters")}
          isDisabled={false}
        />
      </div>
    </>
  );
};

export default FilterComponent;
