import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../../App";
import { useTranslation } from "react-i18next";
import { HiArrowLeft } from "react-icons/hi";
import { Tab } from "@headlessui/react";
import SecondaryButton from "../../components/buttons/secondary-button";
import { PercentField } from "../../components/input/fields/percent-field";
import { CurrencyField } from "../../components/input/fields/currency-field";
import { create, update } from "../../utils/functions";
import TextField from "../../components/input/fields/text-field";
import { SelectMenu } from "../../components/input/select/select-menu";
import { GeneralOptions } from "../../resources/selectOptions";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/buttons/primary-button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { m } from "framer-motion";
import { toast } from "react-toastify";

export const ManageOta = ({ data, isEditable }) => {

  const triggerOptions = GeneralOptions("costDate");
  const paymentTypeOptions = GeneralOptions("paymentType");

  const itemToUpdate = data;
  const { i18n, t } = useTranslation();
  const [IsEditable, setIsEditable] = useState(isEditable || false);
  const Id = itemToUpdate?.id || null;
  const [index, setIndex] = useState(0);
  const context = useContext(DataContext);
  const navigator = useNavigate();
  const [otaName, setOtaName] = useState(itemToUpdate?.name || "");
  const [trigger, setTrigger] = useState(triggerOptions.find((item) => item.value === itemToUpdate?.trigger) || "");
  const [amount, setAmount] = useState(itemToUpdate?.amount || 0);
  const [selectedAccommodations, setSelectedAccommodations] = useState([]);
  const [selectedAccommodationsRow, setSelectedAccommodationsRow] = useState([]);
  const [idAccommodation, setidAccommodation] = useState(null);
  const [idOwner, setIdOwner] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [customRules, setCustomRules] = useState(context.otas);
  const [customRulesRow, setCustomRulesRow] = useState(itemToUpdate?.rulesCustom || []);
  const [paymentType, setPaymentType] = useState(paymentTypeOptions.find((item) => item.value === itemToUpdate?.paymentType) || "");
  const [paymentTypeToPass, setPaymentTypeToPass] = useState(itemToUpdate?.paymentType || "");
  const [triggerToPass, setTriggerToPass] = useState(itemToUpdate?.trigger || "");

  const isMobile = window.matchMedia("(max-width: 767px)").matches;
  useEffect(() => {
    if (customRulesRow.length < 1) {
      addOtaRow();
    }
  }, [customRulesRow.length]);

  const classNames = (...classes) => classes.filter(Boolean).join(" ");

  const accommodationOptions = context.accommodations?.map((item) => ({
    label: item.name,
    value: item.id,
    idOwner: item.idOwner
  })) || [];

  const ownersOptions = Array.isArray(context.owners) ? context.owners.map((item) => ({
    label: item.fullNameCompanyName,
    value: item.id,
  })) : [];

  const accommodationRowOptionsHandle = (idOwner) => { customRules.map(item => {
    if (item.idOwner === idOwner) {
      setSelectedAccommodationsRow(item.rulesCustom.accommodations)
      return item;
    }
  }
  )}

  const checkFields = () => {
    if (!otaName) { 
      toast.error(t("NameRequired"));
      return false;
    }
    if (!trigger) { 
      toast.error(t("TriggerRequired"));
      return false;
    }
    if (!paymentType) { 
      toast.error(t("PaymentTypeRequired"));
      return false;
    }
    if (!amount) { 
      toast.error(t("AmountRequired"));
      return false;
    }
    return true;
  };

  const addOtaRow = () => {
  const newCustomRule = { id: null, idOwner: null, trigger: null, paymentType: null, amount: 0, accommodations: [] };
  const newCustomRules = [...customRulesRow, newCustomRule]; 
  setCustomRulesRow(newCustomRules);
};

  const handleDeleteRow = (index) => {
    const newCustomRules = [...customRulesRow];
    newCustomRules.splice(index, 1);
    setCustomRulesRow(newCustomRules);
  };

  const handleOtaChange = (index, field, value) => {
    const newOtas = [...customRulesRow];
    newOtas[index][field] = value;
    setCustomRulesRow(newOtas);
  };

  const checkCustomRuleFields = () => {
    return customRulesRow.some(rule => 
      rule.accommodations.length > 0 || 
      rule.amount !== 0 || 
      rule.id !== null || 
      rule.idOwner !== null || 
      rule.paymentType !== null || 
      rule.trigger !== null
    );
  };

  const handleCreate = () => {
    if (checkFields()) {

      const formValues = {
        id: Id,
        amount: amount,
        name: otaName,
        paymentType: paymentTypeToPass,
        trigger: triggerToPass
      };
  
      if (checkCustomRuleFields()) {
        formValues.rulesCustom = customRulesRow;
      }

      create("Ota", context.idOrganization, formValues ).then(async () => {
        context.updateData("booking-providers");
        navigator("../otas");
      });
    }
  }

  const handleUpdate = () => {
    // if (checkFields && checkChanges) {
      const formValues = {
        id: Id,
        amount: amount,
        name: otaName,
        paymentType: paymentTypeToPass,
        trigger: trigger
      };

      if (checkCustomRuleFields()) {
        formValues.rulesCustom = customRulesRow;
      }
      update("Ota", context.idOrganization, formValues).then(async () => {
        context.updateData("booking-providers");
        navigator("../otas");
      });

    // };
  }

  return (
    <div className="w-full">
      <div onClick={() => navigator("../otas")} className="mb-10 flex cursor-pointer max-w-[250px]">
        <HiArrowLeft className={"w-6 h-auto hover:text-blue mr-2"} />
        <h1 className="text-2xl font-bold">{t("Ota")}</h1>
      </div>

      <div className="w-full md:pl-24 mt-16">
        <Tab.Group selectedIndex={index} onChange={(value) => setIndex(value)}>
        {!isMobile && (<Tab.List className="flex px-1 space-x-1 text-blue">
              {/** Headers */}
              <div className="tab-manage-container">
                {index === 0 ?
                  (
                    <div className="col-span-12 bg-[rgb(10,109,103)] mx-4 ml-8 rounded-t-xl min-h-[1.3vh]"></div>

                  )
                  :
                  (<div className="col-span-12 bg-[rgb(250,250,250)] mx-4 ml-8 rounded-t-xl min-h-[1.3vh]"></div>)
                }
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "px-8 ml-8 mr-4 pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                      selected
                        ? "selected"
                        : ""
                    )
                  }
                >
                  <div className="grid grid-cols-12">
                    <span className="pt-2 col-span-12 font-semibold text-xl">{t("OtaDetails")}</span>
                  </div>
                </Tab>
              </div>
              <div className="tab-manage-container">
                {index == 1 ?
                  (
                    <div className="col-span-12 bg-[rgb(10,109,103)] mx-4 ml-1 rounded-t-xl min-h-[1.3vh]"></div>

                  )
                  :
                  (<div className="col-span-12 bg-[rgb(250,250,250)] mx-4 ml-1 rounded-t-xl min-h-[1.3vh]"></div>)
                }
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "px-8 ml-1 mr-4 pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                      selected
                        ? "selected"
                        : ""
                    )
                  }
                >
                  <div className="grid grid-cols-12">
                    <span className="pt-2 col-span-12 font-semibold text-xl">{t("Accommodations")}</span>
                  </div>

                </Tab>
              </div>
            </Tab.List>)}
            {isMobile && (<Tab.List className="flex px-1 space-y-2 text-blue flex-col mb-2">
              {/** Headers */}
              <div className="tab-manage-container">
                {index === 0 ?
                  (
                    <div className="col-span-12 bg-[rgb(10,109,103)] min-h-[1.3vh] min-w-[1.3vw]"></div>

                  )
                  :
                  (<div className="col-span-12 bg-[rgb(250,250,250)] min-h-[1.3vh] min-w-[1.3vw]"></div>)
                }
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "  pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                      selected
                        ? "selected"
                        : ""
                    )
                  }
                >
                  <div className="grid grid-cols-12">
                    <span className="pt-2 col-span-12 font-semibold text-left ps-2 text-xl w-[80vw]">{t("OtaDetails")}</span>
                  </div>
                </Tab>
              </div>
              <div className="tab-manage-container">
                {index == 1 ?
                  (
                    <div className="col-span-12 bg-[rgb(10,109,103)] min-h-[1.3vh] min-w-[1.3vw]"></div>

                  )
                  :
                  (<div className="col-span-12 bg-[rgb(250,250,250)] min-h-[1.3vh] min-w-[1.3vw]"></div>)
                }
                <Tab
                  className={({ selected }) =>
                    classNames(
                      " pt-1 pb-3 min-w-[250px] cursor-pointer font-bold bg-neutral-50 tab-manage",
                      selected
                        ? "selected"
                        : ""
                    )
                  }
                >
                  <div className="grid grid-cols-12">
                    <span className="pt-2 col-span-12 font-semibold text-left ps-2 text-xl w-[80vw]">{t("Accommodations")}</span>
                  </div>

                </Tab>
              </div>
            </Tab.List>)}
          <Tab.Panels className=" min-h-[35rem] xl:max-w-[65vw] border-2 rounded-lg py-7 md:px-12 px-1">
            <Tab.Panel key={0} className={classNames("rounded-xl bg-white p-3", "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 space-y-6")}>
              <h1 className="ml-1 text-lg font-semibold text-blue w-full">{t("General")}</h1>
              <div className="md:grid md:grid-cols-2 md:gap-5 md:gap-x-12">
                <div className="w-full mt-1">
                  <TextField
                    label={t("Ota")}
                    inputClassName={"py-3 px-1"}
                    placeholder={t("Insert")}
                    defaultValue={otaName}
                    onChange={(e) => setOtaName(e.target.value)}
                  />
                </div>
                <div className="w-full mt-1">
                  <SelectMenu
                    label={t("Trigger")}
                    className={"md:max-w-xs mr-10 min-w-[27.8vw]"}
                    isSearchable={true}
                    value={trigger}
                    options={triggerOptions}
                    placeholder={t("SelectPlaceholder")}
                    onChange={(value) => {
                      setTrigger(value);
                      setTriggerToPass(value.value);
                    }}
                  />
                </div>
                <div className="w-full mt-1">
                  <SelectMenu
                    label={t("PaymentType")}
                    placeholder={t("SelectPlaceholder")}
                    className={"min-w-[16vw] mb-3 m-0"}
                    inputClassName={"p-1"}
                    options={paymentTypeOptions}
                    onChange={(item) => {
                      const formattedValue = (item.value).toLowerCase()                    
                      setPaymentType(item)
                      setPaymentTypeToPass(formattedValue)
                    }}
                    value={paymentType}
                  />
                </div>
                <div className="w-full mt-1">
                  <CurrencyField
                    label={t("Amount") + " *"}
                    value={amount}
                    className={""}
                    currency={paymentType && paymentType.value !== "fixed" ? null : (context.organizationInfo && context.organizationInfo.currency)}
                    onChange={(el) => setAmount(Number(el.target.value))}
                    inputClassName={"px-1 py-3"}
                  />
                </div>
              </div>
            </Tab.Panel>

            <Tab.Panel key={1} className={classNames("rounded-xl bg-white p-3", "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 space-y-6")}>
              <div className="flex justify-between mb-16 items-center">
                <h1 className="ml-1 text-lg font-semibold text-blue ">{t("AccommodationsInfo")}</h1>
                <div className="flex items-center cursor-pointer flex justify-end min-w-[10vw] md:pt-7 md:pt-0" onClick={addOtaRow}>
                  <FontAwesomeIcon icon={faPlus} />
                  <span className="pl-2 text-right font-semibold min-w-[80px]">{t("AddRow")}</span>
                </div>
              </div>
              <div className="flex flex-col">
                  <div className="">
                    {/* TODO: all'aggiunta si porta dietro i valori di quello che c'era prima */}
                    {customRulesRow.map((ota, index) => (
                      <div key={index} className="md:grid md:grid-cols-12 md:gap-x-4 mb-4">
                        {isMobile &&(
                          <div className="flex justify-between items-center">
                            <span className="  font-semibold">{customRulesRow.length - index}.</span>
                            <button onClick={() => handleDeleteRow(index)} className="text-red-500 ">
                              <FontAwesomeIcon icon={faTimes} />
                            </button>
                          </div>
                          )}
                        <div className="col-span-2 flex relative">
                        {!isMobile && (<span className="absolute bottom-[1rem] left-[-1vw] font-semibold">{customRulesRow.length - index}.</span>)}
                          <SelectMenu
                            placeholder={t("SelectPlaceholder")}
                            className={"md:max-w-xs"}
                            label={t("Owner")}
                            isSearchable={true}
                            value={ownersOptions?.filter(item => ota.idOwner === item.value)}
                            // defaultValue={ownersOptions?.filter(item => ota.idOwner === item.value)}
                            options={ownersOptions}
                            onChange={(value) => handleOtaChange(index, 'idOwner', value.value)}
                          />
                        </div>
                        <div className="col-span-3 ">
                          <SelectMenu
                            label={t("Accommodation") + " *"}
                            placeholder={t("SelectPlaceholder")}
                            isMulti={true}
                            value={customRulesRow[index].accommodations}
                            // value={accommodationOptions.find((item) => {
                            //   if(customRulesRow[index].accommodations === item){

                            //   return item.label;
                            // }
                            // })
                            //   }
                            options={accommodationOptions.filter(item => item.idOwner === ota.idOwner)}
                            onChange={(value) => {
                              handleOtaChange(index, 'accommodations', value.value)
                              // accommodationRowOptionsHandle(ota.idOwner)
                            }}
                          />
                        </div>
                        <div className="col-span-2">
                          <SelectMenu
                            label={t("Trigger")}
                            className={"md:max-w-xs"}
                            isSearchable={true}
                            defaultValue={triggerOptions.find((item) => item.value === customRulesRow[index].trigger)}
                            options={triggerOptions}
                            placeholder={t("SelectPlaceholder")}
                            onChange={(value) => handleOtaChange(index, 'trigger', value.value)}
                          />
                        </div>
                        <div className="col-span-2">
                          <SelectMenu
                            label={t("PaymentType")}
                            className={"md:max-w-xs"}
                            isSearchable={true}
                            value={paymentTypeOptions.find((item) => item.value === customRulesRow[index].paymentType)}
                            options={paymentTypeOptions}
                            placeholder={t("SelectPlaceholder")}
                            onChange={(value) => {
                              const formattedValue = (value.value).toLowerCase();
                              handleOtaChange(index, 'paymentType', formattedValue)
                            }}
                          />
                        </div>
                        <div className="col-span-2">
                          <CurrencyField
                            label={t("Amount") + " *"}
                            placeholder={"0"}
                            labelClassName={'mb-1'}
                            className={"w-full mt-3 md:mt-0"}
                            value={customRulesRow[index]?.amount ?? 0}
                            currency={(customRulesRow[index]?.paymentType?.value ? customRulesRow[index]?.paymentType?.value : customRulesRow[index]?.paymentType) !== "percentage" ? (context.organizationInfo?.currency) : null }
                            onChange={(e) => handleOtaChange(index, 'amount', Number(e.target.value))}
                            inputClassName={"py-3"}
                          />
                      </div>
                      
                <div className="col-span-1 flex items-center justify-center">
                          {!isMobile && (<button onClick={() => handleDeleteRow(index)} className="text-red-500 pt-7">
                            <FontAwesomeIcon icon={faTimes} />
                          </button>)}
                        </div>
                      </div>
                    ))}
                  </div>
              </div>
            </Tab.Panel>
            <div className="flex justify-end w-full pr-3 md:pr-5 pb-10">
              { (
                Id ? (
                  <PrimaryButton
                    content={t("Save")}
                    className={"mt-10 p-3 bg-blue w-[7vw]"}
                    onClick={() => {
                      handleUpdate();
                    }}
                  />
                ) : (
                  <PrimaryButton
                    content={t("Save")}
                    className={"mt-10 p-3 bg-blue w-[7vw]"}
                    onClick={() => {
                      
                      handleCreate();
                    }}
                  />
                )
              )}
            </div>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}
