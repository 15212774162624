import React from "react";

export const TextFieldImaged = ({
  id,
  label,
  labelClassName,
  inputClassName,
  placeholder,
  onChange,
  value,
  onFocusEffect,
  onFocusEffectColor,
  onClick,
  onMouseOver,
  type,
  maxLength,
  autoComplete,
  defaultValue,
  name,
  disabled,
  spellCheck,
  className,
  image,
}) => {

  return (
    <div
      className={
        className + " " + "flex md:flex-col items-center md:items-start md:justify-start justify-between w-full "
      }
    >
      {label && (
        <label
          className={
            labelClassName + " " + "text-lg font-semibold text-blue w-full pe-2 md:pe-0"
          }
        >
          {label}
        </label>
      )}
      <div
        className={
          className +
          " " +
          "flex justify-start items-center bg-input my-1  rounded-lg pl-2 "
        }
      >
        <span className="min-w-[15px]">{image}</span>
        <input
          id={id}
          className={
            inputClassName +
            " " +
            "w-full h-fit bg-input rounded-md outline-none indent-2 text-base text-blue placeholder-color" +
            " " +
            (onFocusEffect
              ? onFocusEffectColor
                ? "focus:border-" + onFocusEffectColor
                : "focus:border-petroil"
              : "")
          }
          placeholder={placeholder}
          type={type ? type : "text"}
          onChange={onChange}
          onClick={onClick}
          onMouseOver={onMouseOver}
          maxLength={maxLength ? maxLength : null}
          autoComplete={autoComplete}
          defaultValue={value}
          name={name}
          disabled={disabled}
          spellCheck={spellCheck}
          min="0.00"
          max="10000.00"
          step="0.01"
        />
      </div>
    </div>
  );
};
